import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const ProjectTile = props => {
  const { project, link } = props
  return (
    <Link to={link || "/projects/" + project.slug}>
    <div>
      <GatsbyImage
        image={getImage(project?.cover?.localFile)}
        class="h-64 md:h-80 w-full"
      />
      <div className="my-8">
        <h2 className="text-xl md:text-3xl 2xl:text-4xl font-playFair">{project.name}</h2>
      </div>
    </div>
    </Link>
  )
}

export default ProjectTile
import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import ContactModal from '../components/contactModal'
import ProjectTile from "../components/projectTile"
import SEO from '../components/seo'

export const query = graphql`
query($slug: String!){
    service: strapiService(slug: {eq: $slug}) {
      slug
      title
      childStrapiServiceDescriptionTextnode {
        description
      }
      Banner {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      projects {
        name
        slug
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
export default function ServiceTemplate({ data }) {
    const service = data.service;
  return (
    <Layout>
      <SEO 
        title={service.title}
      />
        <GatsbyImage
                image={getImage(service?.Banner?.localFile)}
                className="w-full h-96"
            />
            <div className="container mx-auto flex flex-col items-center gap-8 lg:gap-12 text-center">
                <PageHeader 
                    title={service.title}
                    subTitle={service?.childStrapiServiceDescriptionTextnode?.description}
                />
                <ContactModal />
                <div className=' grid grid-cols-3 gap-4'>
                  {service.projects.length > 0 && service.projects.map(project => (
                    <ProjectTile link={"/services/" + service.slug + "/" + project.slug} project={project}/>
                  ))}
                </div>
            </div>     
    </Layout>
  )
}

import React from 'react'

const PageHeader = props => {
    const { title, subTitle } = props;
    return (
        <div className='text-center mb-8 lg:mb-12'>
            <h1 className="font-playFair text-4xl md:text-6xl 2xl:text-7xl my-8 font-bold mx-auto">{title}</h1>
            <p className='w-4/5 mx-auto text-sm md:text-base 2xl:text-xl'>{subTitle}</p>
        </div>
    )
}

export default PageHeader